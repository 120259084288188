import { mcn } from "@/utils";
import { CSSProperties, FC, PropsWithChildren, ReactNode } from "react";
import { ActionBar } from "../ActionBar";
import { Section } from "../Sections";

export type ModalProps = {
  header?: string;
  onRenderHeader?: (
    props?: ModalProps,
    defaultRenderer?: (props?: ModalProps) => ReactNode
  ) => ReactNode;
  onRenderAction?: () => ReactNode;
  className?: string;
  mainClassName?: string;
  style?: CSSProperties;
};

const defaultHeaderRenderer = (props?: ModalProps) => (
  <h1 className={mcn("text-style-headline1")}>{props?.header}</h1>
);

const Modal: FC<PropsWithChildren<ModalProps>> = (props) => {
  return (
    <Section
      color={"blue-polygon"}
      className="max-h-mobile-screen laptop:max-h-screen"
    >
      <div // full screen positioning wrapper
        className={mcn(
          "container flex",
          "min-h-mobile-screen laptop:min-h-screen",
          "py-lg",
          props.className
        )}
      >
        <div // modal main element
          className={mcn(
            "bg-white text-text-primary",
            "shadow-elevation-3 rounded-2",
            "flex-grow",
            "flex flex-col",
            "z-40",
            props.mainClassName
          )}
          style={props.style}
        >
          <div // header
            className={mcn(
              "pt-xl tablet-lg:pt-2xl",
              "px-xl tablet-lg:px-2xl",
              "pb-xl"
            )}
          >
            {props.onRenderHeader
              ? props.onRenderHeader(props, defaultHeaderRenderer)
              : defaultHeaderRenderer(props)}
          </div>
          <div // content
            className={mcn(
              "flex-grow",
              "pb-xl tablet-lg:pb-2xl",
              "px-xl tablet-lg:px-2xl"
            )}
          >
            {props.children}
          </div>

          {props.onRenderAction && (
            <ActionBar>{props.onRenderAction()}</ActionBar>
          )}
        </div>
      </div>
    </Section>
  );
};

export default Modal;
