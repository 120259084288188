export * as MemberUniversityIconAalborg from "./aau.png";
export * as MemberUniversityIconAveiro from "./ua.png";
export * as MemberUniversityIconBarcelona from "./uab.png";
export * as MemberUniversityIconDublin from "./dcu.png";
export * as MemberUniversityIconHamburg from "./tuhh.svg";
export * as MemberUniversityIconInsa from "./insa.png";
export * as MemberUniversityIconKaunas from "./ktu.svg";
export * as MemberUniversityIconLinkoping from "./liu.png";
export * as MemberUniversityIconLodz from "./lodz.png";
export * as MemberUniversityIconMonterrey from "./tec.png";
export * as MemberUniversityIconStavanger from "./uis.jpg";
export * as MemberUniversityIconTampere from "./tau.png";
export * as MemberUniversityIconTrento from "./unitn.jpg";
export * as MemberUniversityIconTwente from "./ut.png";

// INSA Group
export * as MemberUniversityIconINSACVL from "./incvl.png";
export * as MemberUniversityIconINSALyon from "./inlyo.png";
export * as MemberUniversityIconINSARennes from "./inren.png";
export * as MemberUniversityIconINSARouen from "./inrou.png";
export * as MemberUniversityIconINSAStrasbourg from "./instr.png";
export * as MemberUniversityIconINSAToulouse from "./intou.png";
export * as MemberUniversityIconINSAHautsDeFrance from "./inshdf.png";
