import { mcn } from "@/utils/mergeClassNames";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode | undefined;
};
export const ActionBar = (props: Props) => {
  return (
    <div
      className={mcn(
        "flex-shrink-0",
        "flex justify-end items-center",
        "space-x-4 p-4 h-20",
        "border-t border-t-grey-10"
      )}
    >
      {props.children}
    </div>
  );
};
