import { mcn } from "@/utils/mergeClassNames";
import Image, { ImageLoader, StaticImageData } from "next/image";
import { INSAGroupMember, MemberUniversity } from "./memberUniversities";

type Props = {
  logo?: string | StaticImageData | MemberUniversity["logo"];
  name: string;
  loader: ImageLoader;
  className?: string
};

export function getOrganisationLogoPropsFromStaticMemberUniversity(
  memberUniversity: MemberUniversity | INSAGroupMember
): Props {
  return {
    logo: memberUniversity?.logo,
    name: memberUniversity.fullName,
    loader: (props) => props.src,
  };
}

export const OrganisationLogo = ({ logo, name, loader, className }: Props) => {
  return (
    <div
      className={mcn("w-full h-full relative flex justify-center items-center", className)}
    >
      {logo ? (
        <Image
          className="object-contain h-[100px]"
          layout="fill"
          priority
          src={logo}
          alt={name}
          loader={loader}
        />
      ) : (
        <span className="text-style-subtitle1 text-text-primary">{name}</span>
      )}
    </div>
  );
};
