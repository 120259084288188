import {
  MemberUniversityIconAalborg,
  MemberUniversityIconAveiro,
  MemberUniversityIconBarcelona,
  MemberUniversityIconDublin,
  MemberUniversityIconHamburg,
  MemberUniversityIconInsa,
  MemberUniversityIconINSACVL,
  MemberUniversityIconINSAHautsDeFrance,
  MemberUniversityIconINSALyon,
  MemberUniversityIconINSARennes,
  MemberUniversityIconINSARouen,
  MemberUniversityIconINSAStrasbourg,
  MemberUniversityIconINSAToulouse,
  MemberUniversityIconKaunas,
  MemberUniversityIconLinkoping,
  MemberUniversityIconLodz,
  MemberUniversityIconStavanger,
  MemberUniversityIconTampere,
  MemberUniversityIconTrento,
  MemberUniversityIconTwente,
} from "./logo";

export const MemberUniversities = [
  {
    fullName: "Aalborg University",
    shortName: "aau",
    url: "https://www.en.aau.dk/",
    // https://www.design.aau.dk/Valg+af+logo/
    logo: MemberUniversityIconAalborg,
    disabled: true,
  },
  {
    fullName: "Dublin City University",
    shortName: "dcu",
    url: "https://www.dcu.ie/",
    // Zeplin
    logo: MemberUniversityIconDublin,
    disabled: false,
  },
  {
    fullName: "Hamburg University of Technology",
    shortName: "tuhh",
    url: "https://www.tuhh.de/tuhh/en/",
    // https://intranet.tuhh.de/img/logo/TUHH_logo-wortmarke_en_rgb.svg
    // https://intranet.tuhh.de/img/logo/TUHH_logo-wortmarke_en_rgb.png
    logo: MemberUniversityIconHamburg,
    disabled: false,
  },
  {
    fullName: "Institut national des sciences appliquées",
    shortName: "insa",
    url: "https://www.groupe-insa.fr/en",
    //https://www.groupe-insa.fr/en/press-area
    logo: MemberUniversityIconInsa,
    disabled: false,
  },
  {
    fullName: "Kaunas University of Technology",
    shortName: "ktu",
    url: "https://en.ktu.edu/",
    //https://en.ktu.edu/university/brand-resources/
    logo: MemberUniversityIconKaunas,
    disabled: false,
  },
  {
    fullName: "Linköping University",
    shortName: "liu",
    url: "https://liu.se/en",
    //https://liuonline.sharepoint.com/sites/intranet-kommunikation/SitePages/Logotyp.aspx
    logo: MemberUniversityIconLinkoping,
    disabled: false,
  },
  {
    fullName: "Lodz University of Technology",
    shortName: "lodz",
    url: "https://p.lodz.pl/en",
    //https://p.lodz.pl/en/about-tul/promotional-and-information-materials
    logo: MemberUniversityIconLodz,
    disabled: false,
  },
  {
    fullName: "Tampere University",
    shortName: "tau",
    url: "https://www.tuni.fi/en",
    //https://commons.wikimedia.org/wiki/File:Tampere_University_logo.png
    logo: MemberUniversityIconTampere,
    disabled: false,
  },
  // Monterrey is part of ECIU but not ECIU University. Should never be part of the DXP plattform?
  // {
  //   fullName: "Tecnológico de Monterrey",
  //   shortName: "tec",
  //   url: "https://tec.mx/en",
  //   //https://videolab.tec.mx/es/formatos-descargables/identidad-institucional
  //   logo: MemberUniversityIconMonterrey,
  // },
  {
    fullName: "Universitat Autònoma de Barcelona",
    shortName: "uab",
    url: "http://www.uab.cat/",
    // https://www.uab.cat/web/coneix-la-uab-cei/identitat-i-imatge-corporativa/logotips-de-la-uab-1345708315823.html
    logo: MemberUniversityIconBarcelona,
    disabled: false,
  },
  {
    fullName: "University of Aveiro",
    shortName: "ua",
    url: "http://www.ua.pt/",

    //https://www.ua.pt/en/identidade-ua
    logo: MemberUniversityIconAveiro,
    disabled: false,
  },
  {
    fullName: "University of Stavanger",
    shortName: "uis",
    url: "http://www.uis.no/",
    //https://www.uis.no/nb/media/pressebilde-og-uis-logo
    logo: MemberUniversityIconStavanger,
    disabled: false,
  },
  {
    fullName: "University of Trento",
    shortName: "unitn",
    url: "https://www.unitn.it/en",
    // https://webmagazine.unitn.it/en/news/ateneo/67765/new-logo-of-the-university-of-trento-revealed
    logo: MemberUniversityIconTrento,
    disabled: false,
  },
  {
    fullName: "University of Twente",
    shortName: "ut",
    url: "https://www.utwente.nl/en/",
    //https://www.utwente.nl/en/service-portal/communication/visual-corporate-identity-huisstijl/templates-and-downloads-ut-logo-powerpoint-posters-etc#public-templates-and-downloads
    logo: MemberUniversityIconTwente,
    disabled: false,
  },
] as const;

/**
 * French group of graduate engineering schools.
 * @ref https://www.groupe-insa.fr/en
 */
export const INSAGroupMembers = [
  {
    fullName: "Centre Val de Loire",
    shortName: "incvl",
    url: "https://www.insa-centrevaldeloire.fr/en",
    logo: MemberUniversityIconINSACVL,
    disabled: false,
  },
  {
    fullName: "Lyon",
    shortName: "inlyo",
    url: "https://www.insa-lyon.fr/en/",
    logo: MemberUniversityIconINSALyon,
    disabled: false,
  },
  {
    fullName: "Rennes",
    shortName: "inren",
    url: "https://www.insa-rennes.fr/graduate-school-of-engineering.html",
    logo: MemberUniversityIconINSARennes,
    disabled: false,
  },
  {
    fullName: "Rouen",
    shortName: "inrou",
    url: "https://www.insa-rouen.fr/en",
    logo: MemberUniversityIconINSARouen,
    disabled: false,
  },
  {
    fullName: "Strasbourg",
    shortName: "instr",
    url: "https://www.insa-strasbourg.fr/en/",
    logo: MemberUniversityIconINSAStrasbourg,
    disabled: false,
  },
  {
    fullName: "Toulouse",
    shortName: "intou",
    url: "https://www.insa-toulouse.fr/en/index.html",
    logo: MemberUniversityIconINSAToulouse,
    disabled: false,
  },
  {
    fullName: "Hauts-de-France",
    shortName: "inshdf",
    url: "https://www.insa-hautsdefrance.fr/en/",
    logo: MemberUniversityIconINSAHautsDeFrance,
    disabled: false,
  },
] as const;

export type INSAGroupMember = Omit<
  (typeof INSAGroupMembers)[number],
  "disabled"
> & { disabled: boolean };
export type INSAGroupMemberKey = INSAGroupMember["shortName"];

export type MemberUniversity = Omit<
  (typeof MemberUniversities)[number],
  "disabled"
> & { disabled: boolean };
export type MemberUniversityKey = MemberUniversity["shortName"];
export type MemberUniversityKeyOrInsaKey =
  | MemberUniversityKey
  | INSAGroupMemberKey;
